import React, { useState } from "react";
import netlifyIdentity from "netlify-identity-widget";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failed, setFailed] = useState(false);

  const handleSubmit = function (e) {
    e.preventDefault();

    netlifyIdentity.gotrue
      .login(email, password, true)
      .then((response) => {
        netlifyIdentity.store.user = response;
        console.log(`Success! Response: ${JSON.stringify({ response })}`);
      })
      .catch((error) => {
        setEmail("");
        setPassword("");
        setFailed(true);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="login-container">
        <p className="login-title">Welcome to the login page</p>
        <p className="login-text">
          Please enter your e-mail and password below. Note: the authenticated
          content on this website is invite only.
        </p>
        <div className="login-inputs">
          <input
            className="login-email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setFailed(false);
            }}
            type="email"
            size="30"
            placeholder="e-mail address"
          />
          <input
            className="login-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setFailed(false);
            }}
            type="password"
            placeholder="password"
          />
        </div>
        <button className="login-button" type="submit">
          Log in
        </button>
      </form>
      <div className="login-error--div">
        {failed && (
          <p className="login-error">Your email or password are invalid</p>
        )}
      </div>
    </>
  );
}

export default Login;
