import React from "react";
import Logo from "./../media/HKM_Logo.svg";
import "./../App.css";
import Banner from "./../media/HUNKEMOLLER BOW WHITE.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="fotter--banner">
        <img
          className="fotter--banner--img"
          src={Banner}
          alt="Banner"
          height="43px"
        ></img>
      </div>

      <div className="bottom">
        <hr className="line-in-bottom"></hr>
        <div className="bottom--left">
          <a
            href="https://hkmi.com/algemene-voorwaarden "
            className="first--link grey no_underline"
          >
            Disclaimer
          </a>
        </div>

        <div className="bottom--center">
          <a href="/" className="logoFooter">
            <img src={Logo} alt="Hunkemoller"></img>
          </a>
        </div>

        <div className="bottom--right"></div>
      </div>
      <hr className="line"></hr>
      <div className="copyright">
        <p className="grey">Copyright 2023 Hunkemoller</p>
      </div>
    </div>
  );
};

export default Footer;
