import React, { useEffect, useState } from "react";
import Logo from "./../media/HKM_Logo.svg";
import "./../App.css";
import netlifyIdentity from "netlify-identity-widget";

const Header = () => {
  const [user, setUser] = useState("");
  const [loginStatus, setLoginStatus] = useState("Log In");

  netlifyIdentity.init({});

  netlifyIdentity.on("login", (user) => {
    if (!window.location.pathname.startsWith("/private")) {
      window.location.pathname = "/private";
    }
  });

  netlifyIdentity.on("logout", () => {
    window.location.replace(`${window.location.origin}`);
  });

  useEffect(() => {
    const logUser = netlifyIdentity.currentUser();
    if (logUser) {
      setUser((prevUser) => !prevUser);
      setLoginStatus("Log Out");
    }
  }, []);

  const checkIsLogged = function () {
    if (netlifyIdentity.currentUser()) {
      const user = netlifyIdentity.currentUser();
      user
        .logout()
        .then(() => {
          window.location.replace(`${window.location.origin}`);
          setUser((prevUser) => !prevUser);
        })
        .catch((error) => {
          console.log("Failed to logout user: %o", error);
          throw error;
        });
    } else {
      window.location.replace(`${window.location.origin}/login`);
    }
  };

  const redirect = function () {
    window.location.pathname = "/private";
  };

  return (
    <div className="header">
      <div className="MyPrecious"></div>
      <a href="/" className="logo">
        <img src={Logo} alt="Hunkemoller"></img>
      </a>
      {user && (
        <button className="private--btn" onClick={redirect}>
          PRIVATE
        </button>
      )}
      <p onClick={checkIsLogged} className="login dark_grey no_underline">
        {loginStatus}
      </p>
    </div>
  );
};

export default Header;
