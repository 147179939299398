import React from "react";
import './../App.css'
import notFoundImg from "./../media/notFound.png"

function NotFound () {

    return (
        <div className="notFound-container">
            <img className="notFound-img" src={notFoundImg} alt="404 Not Found"></img>
        </div>
    )

}

export default NotFound;