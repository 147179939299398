import React from "react";
import './../App.css'
import HKMIcon from './../media/HKM_icon.svg'

const FileChooser=(props)=>{

    const openPdf = function(props) {
        console.log(props);
        if(props.pdfFile){
            window.open(
                props.pdfFile,
                '_blank'
              );
        }
    }

    return(
        <div className="card">
            <div className="card_body">
                <img className="card--img" onClick={() => openPdf(props)} src={HKMIcon} alt="PDF File"/>
            </div>
            <div className="card-content">
                <div className="card_name">
                    <h5>{props.fileName}</h5>
                </div>
                <div className="card_description">
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    )
}


export default FileChooser;