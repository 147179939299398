import { Builder, BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import { useEffect, useState } from "react";
import Footer from './components/Footer';
import Header from './components/Header';
import openPdfInNewTab from './functions/openPdfInNewTab'
import FileChooser from './components/FileChooser';
import netlifyIdentity from 'netlify-identity-widget'
import NotFound from './components/notFound';
import Login from './components/login';

//SecretKey 
const yourPublicKey = '160b54f162ff4929aa81645a8480dd24';

// bpk-f87e624abb4248a09b6d651d83ed0ae3

// private-key = bpk-69e1cb01248f4d31b02308fa540ac77b

// Put your API key here
builder.init(yourPublicKey)

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [content, setContent] = useState(null);

  // get the page content from Builder
  useEffect( () => {

    let path =  window.location.pathname;
     
    async function fetchContent() {
      let content;
       
      if(path.startsWith('/private')){

        const yourPrivateKey = netlifyIdentity.currentUser()?.app_metadata?.roles[0]

        content = await builder.get('private', {
          url: window.location.pathname,
          authToken: yourPrivateKey
        }).promise();

        setModelType('private');
      }else{
        content = await builder.get('page', {
          url: window.location.pathname
        }).promise();
        
        setModelType('page');
      }
      
      setContent(content);
      setNotFound(!content);
    }
    fetchContent();
  }, []);

  // Checking is it login page
  if(window.location.pathname === '/login'){
    return (
      <>
        <Header/>
          <Login />
        <Footer/>
      </>
    );
  }

  // if no page is found, return a 404 page
  if (notFound && !isPreviewingInBuilder) {
    return <>
      {/* <head>
        <title>Page not found</title>
      </head> */}
      <Header/>
        <NotFound />
      <Footer/>
    </>
  }

  Builder.registerComponent(FileChooser, { 
    name: 'File Chooser',
    defaultStyles: {
      textAlign: "center",
      justifyContent: "space-between",
      fontSize: "20px",
      height: "auto"
    },
    inputs: [{ 
      name: 'pdfFile', 
      type: 'file',
      friendlyName: 'Choose file for the upload:',
      allowedFileTypes: ['pdf'] 
    },
    {
      name:'fileName',
      type: 'string',
      friendlyName: 'This is the name of the file',
      defaultValue: 'Productname maximum characters of xx'
    },
    {
      name: 'description',
      type: 'longText',
      defaultValue: 'Faucibus odio elementum nulla venenatis, libero. Ullamcorper duis fringilla pulvinar nibh diam sit.'
    }
  ]
  })

  // return the page when found
  return (
    <>     
      <Header/>
      <BuilderComponent model={modelType} content={content} context={{ openPdfInNewTab }} />
      <Footer/>
    </>
  );
}
